define(['app', 'debounce', 'viewport', 'columboConstants'], function(app, debounce, viewport, columboConstants) {
  const ColumboEvents = function() {
    const module = {};
    const GTM_TRACKING_CHANNEL = 'tracking/record';
    const GTM_WIDGET_TRACKING_TYPE = 'Widget Track';
    const GTM_WIDGET_TACKING_SUBTYPE_VIEWED = 'Viewed';
    const GTM_WIDGET_TACKING_SUBTYPE_CLICKED = 'Clicked';
    const GTM_DATA_BLOCK_NAME = 'data-block-name';
    const GTM_DATA_WIDGET_ID = 'data-widget-id';
    const GTM_DATA_CONTEXT = 'data-context';
    const GTM_PRODUCT_SKU = 'data-options-sku';
    const DATA_WIDGET_GTM_TRACKING = 'data-widget-gtm-tracking';
    const DATA_WIDGET_GTM_ONLY_TRACKING = 'data-widget-gtm-only-tracking';

    const _config = {
      constants: {
        DEBOUNCE_TIMEOUT: 500
      },
      parent_flag: 'data-columbo-parent',
      selectors: {
        viewedElements: '[data-component-tracked-viewed]',
        clickedElements: '[data-component-tracked-clicked]',
        focusedElements: '[data-component-tracked-focused]',
        hoveredElements: '[data-component-tracked-hovered]',
        pageRoot: 'page-container',
        textField: 'input[type="text"]',
        breadCrumbsItem: '.breadcrumbs_item',
        selectedOption: 'option[selected]',
        productVariationsParent: '.productVariations_dropdown',
      },
    };

    const _init = function() {
      module.viewedElements = Array.from(document.querySelectorAll(module.config.selectors.viewedElements));
      module.clickedElements = Array.from(document.querySelectorAll(module.config.selectors.clickedElements));
      module.focusedElements = Array.from(document.querySelectorAll(module.config.selectors.focusedElements));
      module.hoveredElements = Array.from(document.querySelectorAll(module.config.selectors.hoveredElements));
      module.trackedElementsConfig = module.getTrackedElementsConfig();
    };

    const _getTrackedElementsConfig = function() {
      return module.viewedElements.map((element) => module.getNewConfigObj(element));
    };

    const _createCustomDivSpies = function(spiesConfig) {
      spiesConfig.forEach((spyConfig) => {
        const customDivs = Array.from(document.querySelectorAll(spyConfig.trigger.className));
        customDivs.forEach((customDivs) => {
          customDivs.addEventListener('scroll', debounce(() => module.addTracking(module.focusCallback, module.blurCallback), module.config.constants.DEBOUNCE_TIMEOUT));
          customDivs.addEventListener('resize', debounce(() => module.addTracking(module.focusCallback, module.blurCallback), module.config.constants.DEBOUNCE_TIMEOUT));
        });
      });
    };

    const _getDiffElements = function(newArray, basedArray) {
      return basedArray.filter((element) => {
        if (newArray.indexOf(element) === -1) {
          return element;
        }
      });
    };

    const _updateViewedConfig = function() {
      module.maybeNewViewedElements = Array.from(document.querySelectorAll(module.config.selectors.viewedElements));
      const maybeNewViewedElements = module.getDiffElements(module.viewedElements, module.maybeNewViewedElements);

      if (maybeNewViewedElements.length !== 0) {
        maybeNewViewedElements.forEach((element) => {
          const obj = module.getNewConfigObj(element);
          module.trackedElementsConfig.push(obj);
          module.viewedElements.push(element);
        });
        module.addTracking(module.focusCallback, module.blurCallback);
      }
    };

    const _deepClone = function(obj) {
      return JSON.parse(JSON.stringify(obj));
    };

    const _safeGetValue = function(element, attribute, isInt = false) {
      try{
        return isInt ? Number(element.getAttribute(attribute)) : element.getAttribute(attribute);
      }catch(TypeError) {
        return null;
      }
    };

    const _getTextFieldData = function(element) {
      const textBox = element.querySelectorAll(module.config.selectors.textField)[0];
      if (textBox) {
        return textBox.value ? textBox.value : null;
      }
      return null;
    };

    const _getListPath = function() {
      let listPath = '';
      const pageRoot = document.getElementsByClassName(module.config.selectors.pageRoot)[0];
      if (pageRoot) {
        const breadCrumbs = pageRoot.querySelectorAll(module.config.selectors.breadCrumbsItem);
        for (const crumb of breadCrumbs) {
          try {
            listPath += '/' + crumb.getElementsByTagName('a')[0].innerHTML;
          } catch (ReferenceError) {
            listPath += '/' + crumb.innerHTML;
          }
        }
      }
      if (listPath.length > 0) {
        return listPath;
      }
      return null;
    };

    const _getChildElementInfo = function (element, tag = 'img', attribute = 'src') {
      const minUrlLen = 4;
      for (let childElement of element.querySelectorAll(tag)) {
        if (childElement.getAttribute(attribute).length > minUrlLen) {
          return childElement.getAttribute(attribute);
        }
      }
      return null;
    };

    const _getLinkData = function (element) {
      if (!module.isParentElement(element)){
        return null;
      }
      const firstHref = module.getChildElementInfo(element, 'a', 'href');
      if (firstHref) {
        // If no http:// or // or link has the same url host path as the current site
        if (!firstHref.includes('//') || firstHref.includes(window.location.host)) {
          return {
            link: firstHref,
            is_external_link: false
          };
        }
        return {
          link: firstHref,
          is_external_link: true
        };
      }
      return null;
    };

    const _getRankData = function (element) {
      const selects = element.querySelector(module.config.selectors.productVariationsParent);
      if (selects) {    // Element has variations so we use that data

        const selectedOption = selects.querySelector(module.config.selectors.selectedOption);         // Get the currently selected option
        const options = Array.from(element.getElementsByTagName('option')).slice();

        return {
          quantity: options.length,
          rank: options.indexOf(selectedOption)
        };

      }else{            // See if the element sits within the same container as other elements of the same class
        const relatedItems = Array.from(element.parentElement.getElementsByClassName(element.className)).slice();

        if (relatedItems.length > 0) {
          return {
            quantity: relatedItems.length,
            rank: relatedItems.indexOf(element)
          };
        }
      }
      return {
        quantity: 0,
        rank: 0
      };
    };

    const _isParentElement = function(element){
      try {
        const atts = Array.from(element.attributes).map(x => x.name);
        if (atts.includes(_config.parent_flag)) {
          return true;
        }
      }catch(TypeError) {
        return false;
      }
      return false;
    };

    const _getItems = function(element) {
      if (module.isParentElement(element)) {
        const productDetails = {
          product_group_id: module.safeGetValue(element.querySelector('div[data-master-id]'), 'data-master-id'),
          selected_variant_sku: module.safeGetValue(element.querySelector('div[data-child-id]'), 'data-child-id')
        };
        return [module.removeNulls(Object.assign(productDetails, module.getRankData(element)))];
      }
      return null;
    };

    const hasAttribute = function (attributes, att) {
      return !attributes.includes(att['name']);
    };

    const _getAttributes = function(element) {
      // List of attributes to filter out from stored attributes
      const filterAtts = columboConstants.filterAtts;
      const allAttributes = Array.from(element.attributes).filter((att) => hasAttribute(filterAtts, att));
      let filteredAtts = [];
      for (let attribute of allAttributes) {
        filteredAtts.push({name: attribute['name'], value: attribute['value']});
      }
      if (filteredAtts.length > 0){
        return filteredAtts;
      }
      return null;
    };

    // Removes null properties from an object
    const _removeNulls = function(objectWithNulls) {
      for (let valueNm in objectWithNulls) {
        if (objectWithNulls[valueNm] === null ||
            objectWithNulls[valueNm] === undefined) {
          delete objectWithNulls[valueNm];
        }
      }
      return objectWithNulls;
    };

    const _getContents = function(element) {
      let contents = Object.assign({
        html_element: module.safeGetValue(element, 'data-context'),
        widget_id: module.safeGetValue(element, 'data-widget-id', true),
        list_path: module.getListPath(),
        items: module.getItems(element),
        text: module.getTextFieldData(element)
      },module.getLinkData(element));
      return [module.removeNulls(contents)];
    };

    // Required for bot filtering
    const _getClassName = function(element) {
      return element.className.split(' ')[0];
    };

    const _getData = function(element) {
      return module.removeNulls({
        subtype: module.getClassName(element),
        is_interaction: false,
        contents: module.getContents(element),
        attributes: module.getAttributes(element),
        viewport_width: Math.round(viewport.getMaxClientOrInnerWidth()),
        viewport_height: Math.round(viewport.getMaxClientOrInnerHeight()),
        from_viewport_top: Math.round(viewport.getFromViewportTop(element)),
        from_viewport_left: Math.round(viewport.getFromViewportLeft(element))
      });
    };

    const _addElementClickedTracking = function(elements, elementClickedConfig, publishEvent) {
      elements.forEach((element) => {
        if (element.hasAttribute(DATA_WIDGET_GTM_ONLY_TRACKING)) {
          element.addEventListener('click', module.handleGTMClickedTracking.bind(null, element));
        } else {
          if (element.hasAttribute(DATA_WIDGET_GTM_TRACKING)) {
            element.addEventListener('click', module.handleGTMClickedTracking.bind(null, element));
          }

          element.addEventListener('click', (event) => {
            let target = event && event.target;
            let element = target && target.closest('[data-context]');
            if (element) {
              const data = module.getData(element);
              data.type = 'click';
              publishEvent(elementClickedConfig, data)
            }
          });
        }
      });
    };

    const _findNewClickedTracking = function(elementClickedConfig, publishEvent) {
      module.maybeNewClickedElements = Array.from(document.querySelectorAll(module.config.selectors.clickedElements));
      // this is an O(n^2) algorithm, much better alternative would be to tag each element once they're added
      // and only re-add the untagged element (complexity O(n))
      const maybeNewClickedElements = module.getDiffElements(module.clickedElements, module.maybeNewClickedElements);
      if (maybeNewClickedElements.length !== 0) {
        module.addElementClickedTracking(maybeNewClickedElements, elementClickedConfig, publishEvent);
        module.clickedElements = module.clickedElements.concat(maybeNewClickedElements);
      }
    };

    const _elementClicked = function(elementClickedConfig, publishEvent) {
      module.findNewClickedTracking(elementClickedConfig, publishEvent);
      module.addElementClickedTracking(module.clickedElements, elementClickedConfig, publishEvent);
      window.setInterval(() => {
        module.findNewClickedTracking(elementClickedConfig, publishEvent);
      }, module.config.constants.DEBOUNCE_TIMEOUT)
    };

    const _getNewConfigObj = function(element) {
      const obj = {};
      obj.element = element;
      obj.isViewed = false;
      obj.isFirstTimeSeen = true;
      obj.sendGTMEvent = true;
      return obj;
    };

    const _getDisplayProperty = function(element) {
      return element ? window.getComputedStyle(element).getPropertyValue('display') : null;
    };

    const _sendFocusEvent = function(config, data, isElementDisplayable, publishCallbackFocus) {
      config.isFirstTimeSeen = false;
      config.isViewed = true;
      config.isElementDisplayable = isElementDisplayable;
      data.type = 'focus';
      publishCallbackFocus(data);
    };

    const _sendBlurEvent = function(config, data, isElementDisplayable, publishCallbackBlur) {
      config.isElementDisplayable = isElementDisplayable;
      config.isViewed = false;
      data.type = 'blur';
      publishCallbackBlur(data);
    };

    const _isElementDisplayable = function(trackedElement) {
      const displayProperty = module.getDisplayProperty(trackedElement);
      const parentDisplayProperty = module.getDisplayProperty(trackedElement.parentNode);
      const isDisplayable = displayProperty !== '' && displayProperty !== 'none';
      const isParentDisplayable = parentDisplayProperty !== '' && parentDisplayProperty !== 'none';
      return isDisplayable && isParentDisplayable;
    };

    const _getFocusCallback = function(elementViewedConfig, publishEvent) {
      const configElementFocus = module.deepClone(elementViewedConfig);
      configElementFocus.key += 'Focus';
      configElementFocus.publish.event += '-focus';
      return function(data) {
        publishEvent(configElementFocus, data);
      };
    };

    const _getBlurCallback = function(elementViewedConfig, publishEvent) {
      const configElementBlur = module.deepClone(elementViewedConfig);
      configElementBlur.key += 'Blur';
      configElementBlur.publish.event += '-blur';
      return function(data) {
        publishEvent(configElementBlur, data);
      }
    };

    const _addTracking = function(focusCallback, blurCallback) {
      module.trackedElementsConfig.forEach((config) => {

        const trackedElement = config.element;
        const isElementDisplayable = module.isElementDisplayable(trackedElement);
        const isElementInTheViewport = viewport.isElementVisible(trackedElement);

        if (trackedElement.hasAttribute(DATA_WIDGET_GTM_ONLY_TRACKING)) {
          module.handleGTMViewedTracking(config, isElementDisplayable, isElementInTheViewport);
        } else {
          if (trackedElement.hasAttribute(DATA_WIDGET_GTM_TRACKING)) {
            module.handleGTMViewedTracking(config, isElementDisplayable, isElementInTheViewport);
          }

          const data = module.getData(trackedElement);
          if (config.isElementDisplayable && !isElementDisplayable) {
            module.sendBlurEvent(config, data, isElementDisplayable, blurCallback);
          }

          if (isElementDisplayable) {
            if (isElementInTheViewport && !config.isViewed) {
              module.sendFocusEvent(config, data, isElementDisplayable, focusCallback);
            }

            if (!isElementInTheViewport && config.isViewed && !config.isFirstTimeSeen) {
              module.sendBlurEvent(config, data, isElementDisplayable, blurCallback);
            }
          }
        }
      });
    };

    const _elementViewed = function(elementViewedConfig, publishEvent) {
      module.focusCallback = module.getFocusCallback(elementViewedConfig, publishEvent);
      module.blurCallback = module.getBlurCallback(elementViewedConfig, publishEvent);

      if (document.readyState === 'complete') {
        module.addTracking(module.focusCallback, module.blurCallback);
      } else {
        window.addEventListener('load', () => module.addTracking(module.focusCallback, module.blurCallback));
      }
      window.addEventListener('scroll', debounce(() => module.addTracking(module.focusCallback, module.blurCallback), module.config.constants.DEBOUNCE_TIMEOUT));
      window.addEventListener('resize', debounce(() => module.addTracking(module.focusCallback, module.blurCallback), module.config.constants.DEBOUNCE_TIMEOUT));
      window.setInterval(module.updateViewedConfig, module.config.constants.DEBOUNCE_TIMEOUT);
    };

    const _elementFocused = function(config, publish) {
      module.focusedElements.map(element => {
        const focusData = module.getData(element);
        const blurData = module.getData(element);

        focusData.type = 'input-focus';
        blurData.type = 'input-blur';

        element.addEventListener('focus', () => publish(config, focusData));
        element.addEventListener('blur', () => publish(config, blurData));
      });
    };

    const _elementHovered = function(config, publish) {
      module.hoveredElements.map(element => {
        const enterData = module.getData(element);
        const exitData = module.getData(element);

        enterData.type = 'mouseenter';
        exitData.type = 'mouseleave';

        element.addEventListener('mouseenter', debounce(() => publish(config, enterData), module.config.constants.DEBOUNCE_TIMEOUT));
        element.addEventListener('mouseleave', debounce(() => publish(config, exitData), module.config.constants.DEBOUNCE_TIMEOUT));
      });
    };

    const _handleGTMTracking = (element, trackingSubtype) => {

      if (element.hasAttribute(GTM_DATA_CONTEXT)) {
        app.publish(GTM_TRACKING_CHANNEL,
          GTM_WIDGET_TRACKING_TYPE, trackingSubtype,
          element.getAttribute(GTM_DATA_BLOCK_NAME),
          element.getAttribute(GTM_DATA_WIDGET_ID),
          element.getAttribute(GTM_DATA_CONTEXT))
      } else {
        // get the name of the widget slot
        let widgetSlot = '';

        if (element.classList.contains('trackwidget')) {
          widgetSlot = module.widgetSlot(element)
        }

        var name = trackingSubtype;
        if(element.getAttribute(GTM_PRODUCT_SKU)) {
          name += ' | ' + element.getAttribute(GTM_PRODUCT_SKU);
        }
        app.publish(GTM_TRACKING_CHANNEL,
          GTM_WIDGET_TRACKING_TYPE, name,
          element.getAttribute(GTM_DATA_BLOCK_NAME),
          element.getAttribute(GTM_DATA_WIDGET_ID),
          widgetSlot
        )
      }
    };

    const _handleGTMViewedTracking = (config, isDisplayable, isInViewport) => {
      const trackedElement = config.element;

      if (isDisplayable && isInViewport && config.sendGTMEvent) {
        // set the attribute to avoid sending duplicated events
        config.sendGTMEvent = false;

        module.handleGTMTracking(trackedElement, GTM_WIDGET_TACKING_SUBTYPE_VIEWED);
      }
    };

    const _handleGTMClickedTracking = (element) => {
      module.handleGTMTracking(element, GTM_WIDGET_TACKING_SUBTYPE_CLICKED);
    };
    const _findWidgetSlotComment = (element) => {

      let currentNode = element;
      let maxParentLevels = 5;

      while ((currentNode !== null && currentNode !== undefined) && maxParentLevels > 0) {
        let maxSiblinglevels = 5;
        let sibling = currentNode.previousSibling;

        while ((sibling !== null && sibling !== undefined) && maxSiblinglevels > 0) {

          if (sibling.nodeType === Node.COMMENT_NODE) {
            return sibling.nodeValue.trim();
          }
          sibling = sibling.previousSibling;
        }

        currentNode = currentNode.parentNode;
        maxSiblinglevels--
      }

      maxParentLevels--;
    };

    const _widgetSlot = (element) => {
      if(element) {
        const slot = module.findWidgetSlotComment(element);
        return module.widgetSlotCommentText(slot);
      }
    }

    const _widgetSlotCommentText = (comment) => {
      if (comment) {
        let removeCommentChars = comment.replace(/[^a-zA-Z0-9 ]/g, '').trim()
        let slotString = removeCommentChars.replace(/(start|end)$/, '').trim()
        
        return slotString
      }
    }


    module.init = _init;
    module.config = _config;
    module.addElementClickedTracking = _addElementClickedTracking;
    module.addTracking = _addTracking;
    module.createCustomDivSpies = _createCustomDivSpies;
    module.elementViewed = _elementViewed;
    module.elementClicked = _elementClicked;
    module.elementFocused = _elementFocused;
    module.elementHovered = _elementHovered;
    module.deepClone =_deepClone;
    module.findNewClickedTracking = _findNewClickedTracking;
    module.getBlurCallback = _getBlurCallback;
    module.getData = _getData;
    module.getContents = _getContents;
    module.getClassName =_getClassName;
    module.getNewConfigObj = _getNewConfigObj;
    module.getDisplayProperty = _getDisplayProperty;
    module.getDiffElements = _getDiffElements;
    module.getFocusCallback = _getFocusCallback;
    module.getTrackedElementsConfig = _getTrackedElementsConfig;
    module.isElementDisplayable = _isElementDisplayable;
    module.sendFocusEvent = _sendFocusEvent;
    module.sendBlurEvent = _sendBlurEvent;
    module.updateViewedConfig = _updateViewedConfig;
    module.getTextFieldData = _getTextFieldData;
    module.safeGetValue = _safeGetValue;
    module.getRankData = _getRankData;
    module.getContents = _getContents;
    module.getAttributes = _getAttributes;
    module.getListPath = _getListPath;
    module.getChildElementInfo = _getChildElementInfo;
    module.getLinkData = _getLinkData;
    module.getItems = _getItems;
    module.removeNulls = _removeNulls;
    module.isParentElement = _isParentElement;
    module.getContents = _getContents;
    module.handleGTMViewedTracking = _handleGTMViewedTracking;
    module.handleGTMClickedTracking = _handleGTMClickedTracking;
    module.handleGTMTracking = _handleGTMTracking;
    module.findWidgetSlotComment = _findWidgetSlotComment;
    module.widgetSlotCommentText = _widgetSlotCommentText;
    module.widgetSlot = _widgetSlot;
    module.init();

    return module;
  };

  return ColumboEvents;
});
